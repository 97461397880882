<template>
  <div id="container" class="mine-container">
    <el-menu class="mine-menu" :router="true">
      <el-menu-item v-for="(item, index) in menus" :index="item.index" :key="index" :route="item.route">
        <i :class="item.icon"></i>
        <span slot="title">{{item.title}}</span>
      </el-menu-item>
    </el-menu>
    <router-view ref="router-mine"></router-view>
  </div>
</template>

<script>
import { AutoBodyHeight } from '@/mixins/body-height'

export default {
  mixins: [ AutoBodyHeight ],
  components: {},
  props: {},
  data () {
    return {
      menus: [{
        title: 'Dashboard',
        icon: 'el-icon-s-marketing',
        index: '/mine/instru',
        route: '/mine/instru'
      }, {
        title: 'Bill',
        icon: 'el-icon-s-order',
        index: '/mine/bill',
        route: '/mine/bill'
      }, {
        title: 'My Profile',
        icon: 'el-icon-s-cooperation',
        index: '/mine/info',
        route: '/mine/info'
      }, {
        title: 'Logs',
        icon: 'el-icon-s-management',
        index: '/mine/logs',
        route: '/mine/logs'
      }]
    }
  },
  computed: {},
  watch: {},
  methods: {},
  created () {},
  mounted () {},
  destroyed () {}
}
</script>
